.cSlideQuestion {
  margin:3vw 0 0 0;
  &-title {
    text-align: center;
    font-family: $f-america;
    font-weight: bold;
    font-size: 2vw;
    margin: 0 0 1vw 0;
  }
  &-answers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  &-answer {
    font-size: 1.75vw;
    width: 8vw;
    height: 8vw;
    margin: 4px;
    border: 2px solid $c-white;
    background: $c-white;
    border-radius: 100%;
    display: block;
    position: relative;
    @extend .animatable;
    > span {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }
    &.active {
      border: 2px solid $c-teal;
    }
  }
}
