.cSlide {
  @extend .animatable;
  opacity: 0;
  margin: auto;
  padding: 5%;
  box-sizing: border-box;
  width: 100%;
  border: 5px solid $c-black;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-title {
    text-align: center;
    font-family: $f-america;
    font-weight: bold;
    font-size: 5vw;
    +.cSlide-subtitle, ~.cSlide-text {
      margin-top: 2vw;
    }
  }
  &-subtitle {
    text-align: center;
    font-family: $f-america;
    font-weight: bold;
    font-size: 3.5vw;
    margin: 0 0 5vw 0;
  }
  &-text {
    text-align: center;
    font-family: $f-america;
    font-size: 2vw;
    margin: 0 0 1vw 0;
  }
  &-buttons {
    margin: 8vh 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
      margin-top: 1vw;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  &--result .cSlide-buttons {
    margin-top: 1.5vw;
  }
  &-info {
    @include animatable;
    width: 75vw;
    max-width: 600px;
    display: block;
    margin: 5vw auto 0 auto;
    text-align: center;
    letter-spacing: 0.025em;
    line-height: 22px;
    font-size: 1.5vw;
    span {
      text-decoration: underline;
    }
  }
  &-email {
    width: 75vw;
    max-width: 420px;
    display: block;
    margin: 5vw auto 0 auto;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid $c-black;
    font-size: 1.75vw;
    text-align: center;
  }
  &.loaded {
    opacity: 1;
  }
}
