@import 'variables';
@import 'fonts/index';
@import 'components/buttons';
@import 'components/slides';
@import 'components/multipleChoice';

.App {
  background: $c-beige;
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
