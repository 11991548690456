.cButton {
  appearance: none;
  -webkit-appearance: none;
  background:none;
  border: none;
  &[data-flavor="primary"] {
    font-weight: bold;
    letter-spacing: 0.025em;
    font-family: $f-america;
    padding: 1.75vw 2vw;
    min-width: 20vw;
    background: $c-yellow;
    border-radius: 30px;
    border: 3px solid $c-white;
    line-height: 1;
    font-size: 2vw;
    @extend .animatable;
    &:active {
      border-color:$c-yellow;
      background: $c-white;
      transform:translateY(1px)
    }
    &.disabled {
      pointer-events: none;
      opacity:0.4;
    }
  } 
  &[data-flavor="link"] {
    text-decoration: underline;
    font-size: 1.5vw;
  }
}
