
/* Colors */
$c-white: #ffffff;
$c-black: #000000;
$c-red: #dd0000;
$c-darkred: #ff0000;
$c-pink: #fac6cc;
$c-aqua: #33f9cb;
$c-blue: #0071bc;
$c-teal: #33facc;
$c-yellow: #feff00;
$c-beige: #f9f8f5;

/* Animations */
$animation-function: cubic-bezier(0.497, 0.51, 0.25, 1);
$animation-delay: 350ms;

/* Fonts */
$f-america: 'GT America Web', Helvetica, Arial, sans-serif;

@mixin animatable{
  transition-timing-function:$animation-function;
  transition-duration: $animation-delay;
  transition-property: all;
}
.animatable{
	 @include animatable;
}